// import the original getStaticReducers function
// and rename it to baseGetStaticReducers
import { getStaticReducers as baseGetStaticReducers } from 'SourceStore/index';
import BlogReducer from 'Store/Blog/Blog.reducer';
// import our own reducer
import StoresReducer from 'Store/Stores/Stores.reducer';
import UiReducer from 'Store/Ui/Ui.reducer';

// define getStaticReducers.
// this is a function that returns an object of all the reducers in the app.
// just like in the base theme...
/** @namespace Bodypwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...baseGetStaticReducers(),

    // ...except we also add our own reducer to the mix
    BlogReducer,
    StoresReducer,
    UiReducer
});

// nothing new here, just copying the function from the base theme
// (this is necessary so that it uses our own `getStaticReducers` function
export default function injectStaticReducers(store) {
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
