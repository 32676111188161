import {
    defaultConfig as sourceDefaultConfig,
    getInitialState as sourceGetInitialState,
    ProductListReducer as sourceProductListReducer
} from 'SourceStore/ProductList/ProductList.reducer';
import { getIndexedProducts } from 'Util/Product';
import { mergeProducts } from 'Util/Variants';

import {
    APPEND_PAGE,
    CLEAN_PRODUCT_LIST,
    REMOVE_PRODUCT_LIST_ITEMS,
    UPDATE_CATEGORY_PAGE,
    UPDATE_PRODUCT_LIST,
    UPDATE_PRODUCT_LIST_ITEMS
}
from './ProductList.action';

/** @namespace Bodypwa/Store/ProductList/Reducer/getInitialState */
export const getInitialState = () => ({
    categoriesList: {},
    categoryPage: 1,
    ...sourceGetInitialState()
});

export const defaultConfig = sourceDefaultConfig;

/** @namespace Bodypwa/Store/ProductList/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        items: initialItems = [],
        total_pages: totalPages,
        total_count: totalItems,
        currentPage,
        id: categoryId,
        args: currentArgs,
        categoryPage
    } = action;

    const {
        pages,
        id: currentCategoryId
    } = state;

    switch (type) {
    case APPEND_PAGE:
        const { mergedItems: mergedItemsAppend } = mergeProducts(
            pages[currentPage], getIndexedProducts(initialItems), currentPage
        );

        if (categoryId !== currentCategoryId) {
            return {
                ...state,
                isPageLoading: false,
                id: categoryId,
                currentPage,
                pages: {
                    ...state.pages,
                    [currentPage]: getIndexedProducts(initialItems)
                }
            };
        }

        return {
            ...state,
            isPageLoading: false,
            id: categoryId,
            currentPage,
            pages: {
                ...state.pages,
                [currentPage]: mergedItemsAppend.length ? mergedItemsAppend : getIndexedProducts(initialItems)
            }
        };
    case UPDATE_PRODUCT_LIST_ITEMS:
        if (categoryId !== currentCategoryId) {
            return {
                ...state,
                currentArgs,
                id: categoryId,
                isLoading: false,
                currentPage,
                totalItems,
                totalPages,
                pages: { [currentPage]: getIndexedProducts(initialItems) }
            };
        }
        const { mergedItems: mergedItemsUpdate } = mergeProducts(
            pages[currentPage], getIndexedProducts(initialItems), currentPage
        );

        return {
            ...state,
            currentArgs,
            id: categoryId,
            isLoading: false,
            currentPage,
            totalItems,
            totalPages,
            pages: { [currentPage]: mergedItemsUpdate.length ? mergedItemsUpdate : getIndexedProducts(initialItems) }
        };
    case UPDATE_PRODUCT_LIST:
        const {
            productListAddtionalData: {
                productsList: productListWithUpdates = [],
                page
            }
        } = action;

        if (categoryId !== currentCategoryId) {
            return {
                ...state,
                id: categoryId,
                pages: {
                    ...pages,
                    [page]: getIndexedProducts(productListWithUpdates)
                }
            };
        }
        const { mergedItems } = mergeProducts(pages[page], getIndexedProducts(productListWithUpdates), page);
        return {
            ...state,
            id: categoryId,
            pages: {
                ...pages,
                [page]: mergedItems
            }
        };
    case CLEAN_PRODUCT_LIST:
        return {
            ...getInitialState()
        };
    case REMOVE_PRODUCT_LIST_ITEMS:
        const newPages = Object.keys(pages)
            .filter((key) => key !== currentPage)
            .reduce((acc, key) => ({ ...acc, [key]: pages[key] }), {});

        return {
            ...state,
            pages: newPages
        };
    case UPDATE_CATEGORY_PAGE:
        return {
            ...state,
            categoryPage
        };
    default:
        return sourceProductListReducer(state, action);
    }
};

export default ProductListReducer;
